import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import DynamicThankyou from '../../components/ThankyouPages/DynamicThankyou';
import { useLayoutQueries } from '../../utils/queries';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import adbutlerZones from '../../utils/adbutlerZones';
import { capitalize } from '../../utils/Validation/characterValidation';

const ThankYouBCP = ({
  pageContext: { style = {}, name = 'thankyouBCP' },
  config,
  path,
}) => {
  const [adIds, setAdIds] = useState([]);
  const [tyType, setTyType] = useState('');
  const [isILX, setIsILX] = useState(true);
  const [params, setParams] = useState({});

  useEffect(() => {
    const query = parse(window.location.search);
    const loanPurpose = capitalize(query.loan_purpose);
    const adObject = adbutlerZones(name, false, params, config);
    let creditRating;
    const goodCreditArr = ['Excellent', 'Good', 'Average'];

    if (goodCreditArr.includes(capitalize(query.credit_rating))) {
      creditRating = 'GoodCredit';
    } else {
      creditRating = 'BadCredit';
    }
    setParams(query);
    setTyType(`${loanPurpose}${creditRating}`);

    if (adObject[tyType]) {
      setAdIds(adObject[tyType]);
    } else {
      setAdIds(adObject.default);
    }
    // eslint-disable-next-line
  }, [tyType, name]);
  return (
    <Fragment>
      {!isILX && (
        <DynamicThankyou
          adButlerIds={adIds}
          adButlerAcctId="169214"
          params={params}
          config={config}
          path={path}
        />
      )}
    </Fragment>
  );
};

ThankYouBCP.propTypes = {
  pageContext: PropTypes.object,
  config: PropTypes.object,
  path: PropTypes.string,
};

export default withThankyouWrapper(ThankYouBCP);
